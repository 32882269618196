// store.js
import { createStore } from 'vuex';

const store = createStore({
  state: {
    campaigncreate: true,
    campaignEdit: false,
    scrollcreate:true,
    scrollEditPage:false,
    userId:null,
  },
  mutations: {
    setCreateCampaign(state) {
      state.campaigncreate = true;
      state.campaignEdit = false;
    },
    setEditCampaign(state) {
      state.campaigncreate = false;
      state.campaignEdit = true;
    },
    setCreateScroll(state) {
        state.scrollcreate = true;
        state.scrollEditPage = false;
      },
      setEditScroll(state) {
        state.scrollcreate = false;
        state.scrollEditPage = true;
      },
      setUserId(state, userId) {
        state.userId = userId;
      },
  },
});

export default store;
