
import { io } from 'socket.io-client';


//const socket = io('http://127.0.0.1:5050');107.155.65.19,209.182.233.252
const userData = JSON.parse(localStorage.getItem('userData'))
let socket = '';

if (userData) {
    socket = io('http://209.182.233.252:3000', {
        transportOptions: {
            polling: {
                extraHeaders: {
                    username: userData.username
                }
            }
        }
    })
} else {
    socket = io('http://209.182.233.252:3000');
}



export default socket;