import { canNavigate } from '@layouts/plugins/casl'
import { setupLayouts } from 'virtual:generated-layouts'
import { createRouter, createWebHistory } from 'vue-router'
import routes from '~pages'
import { isUserLoggedIn } from './utils'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    // ℹ️ We are redirecting to different pages based on role.
    // NOTE: Role is just for UI purposes. ACL is based on abilities.
    // {
    //   path: '/',
    //   redirect: to => {
    //     const userData = JSON.parse(localStorage.getItem('userData') || '{}')
    //     console.log(userData,"USER DATa");
    //     const userRole = (userData && userData.role) ? userData.role : null
    //     console.log(userRole === 'admin',"userRole === 'admin'");
    //     if (userRole === 'admin')
    //       return { name: 'dashboards-campaigns' } 
    //     if (userRole === 'client')
    //       return { name: 'superAdmin-ac' }

    //     return { name: 'login'}
    //   },

    // },
    {
      path: '/',
      redirect: to => {
        const userData = JSON.parse(localStorage.getItem('userData') || '{}')
        const userRole = (userData && userData.role) ? userData.role : null
        if (userRole === 'admin' || userRole === 'user' || userRole === 'author' || userRole === 'manager')
          return { name: 'dashboards-adminDashboard' }
        if (userRole === 'reseller')
          return { name: 'dashboards-resellerDashboard' }
        if (userRole === 'superAdmin')
          return { name: 'superAdmin-components-clientsSection' }
        //return { name: 'access-control' }      

        return { name: 'login', query: to.query }
      },
    },
    ...setupLayouts(routes),
  ],
})


// router.beforeEach(to => {

//   const isLoggedIn = isUserLoggedIn();
//   const userDatas = JSON.parse(localStorage.getItem('userData') || '{}');
//   const userRole = userDatas.role;

//   if (userRole !== 'superAdmin' && to.path.includes('/superadmin')) {
//     return { name: 'not-authorized' }; // Redirect to "not-authorized" for non-client roles
//   } else if (userRole === 'superAdmin' && to.path.includes('/dashboards/resellerdashboard')) {
//     return;
//   } else if (userRole === 'superAdmin' && to.path.includes('/dashboards')) {
//     console.log("I AM HERE", to.path);
//     return { name: 'not-authorized' }; // Redirect to "not-authorized" for non-client roles
//   }

//   if (canNavigate(to)) {

//     if (to.meta.redirectIfLoggedIn && isLoggedIn) {

//       return '/';
//     } else {
//       // return to;
//     }
//   } else {
//     console.log("(isLoggedIn  else)", isLoggedIn);
//     if (isLoggedIn) {
//       if (userRole === 'superAdmin') {
//         return to; // Allow navigation for clients
//       } else {
//         return { name: 'not-authorized' }; // Redirect to "not-authorized" for non-client roles
//       }
//     } else {
//       return { name: 'login', query: { to: to.name !== 'index' ? to.fullPath : undefined } };
//     }
//   }

// });

router.beforeEach(to => {
  const isLoggedIn = isUserLoggedIn();
  const userDatas = JSON.parse(localStorage.getItem('userData') || '{}');
  const userRole = userDatas.role;

  if (userRole !== 'superAdmin' && to.path.includes('/superadmin')) {
    return { name: 'not-authorized' }; // Redirect to "not-authorized" for non-client roles
  } else if (userRole === 'superAdmin' && to.path.includes('/dashboards/resellerdashboard')) {
    return;
  } else if (userRole === 'superAdmin' && to.path.includes('/dashboards')) {
    return { name: 'not-authorized' }; // Redirect to "not-authorized" for non-client roles
  }

  if (canNavigate(to)) {
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      return '/';
    }
  } else {
    if (isLoggedIn) {
      if (userRole === 'superAdmin') {
        return to; // Allow navigation for clients
      } else {
        return { name: 'not-authorized' }; // Redirect to "not-authorized" for non-client roles
      }
    } else {
      // Redirect to login with original URL as query parameter
      return { name: 'login', query: { to: to.name !== 'index' ? to.fullPath : undefined } };
    }
  }
});



export default router



// Docs: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
// router.beforeEach(to => {
//   const isLoggedIn = isUserLoggedIn()

//   /*

//     ℹ️ Commented code is legacy code

//     if (!canNavigate(to)) {
//       // Redirect to login if not logged in
//       // ℹ️ Only add `to` query param if `to` route is not index route
//       if (!isLoggedIn)
//         return next({ name: 'login', query: { to: to.name !== 'index' ? to.fullPath : undefined } })

//       // If logged in => not authorized
//       return next({ name: 'not-authorized' })
//     }

//     // Redirect if logged in
//     if (to.meta.redirectIfLoggedIn && isLoggedIn)
//       next('/')

//     return next()

//     */
//   if (canNavigate(to)) {
//     if (to.meta.redirectIfLoggedIn && isLoggedIn)
//       return '/'
//   }
//   else {
//     if (isLoggedIn)
//       return { name: 'not-authorized' }
//     else
//       return { name: 'login', query: { to: to.name !== 'index' ? to.fullPath : undefined } }
//   }
// })

// router.beforeEach(to => {
//   console.log("beforeEach");
//   const isLoggedIn = isUserLoggedIn();

//   if (canNavigate(to)) {
//     if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//       return { name: 'dashboards-campaigns' }; // Replace 'dashboards-campaigns' with the appropriate route name
//     }
//   } else {
//     if (isLoggedIn) {
//       return { name: 'not-authorized' };
//     } else {
//       return { name: 'login', query: { to: to.name !== 'index' ? to.fullPath : undefined } };
//     }
//   }
// });
